<template>
  <div>
    <b-button v-b-toggle.collapse-3 class="m-1">
      <span class="when-open" />
      <span class="when-closed" />
      {{ $t('catalog.xmlPreview.showXmlPreview') }}
    </b-button>
    <b-collapse id="collapse-3" :visible="showXmlPreview">
      <b-card>
        <div ref="container">
          <vs-table :data="getPreviewData" stripe class="mb-base">
            <vs-tr v-for="(item,key) in getXmlProductPreviewData" :key="key">
              <vs-td>
                {{ key }}
              </vs-td>
              <vs-td>
                {{ item }}
              </vs-td>
            </vs-tr>
          </vs-table>
          <vs-col vs-w="12" vs-type="flex" vs-justify="right" vs-align="center" class="mt-1">
            {{ productNum + 1 }} / {{ getMax + 1 }}
          </vs-col>
          <vs-col vs-w="12" vs-type="flex" vs-justify="right" vs-align="center" class="mt-2">
            <vs-button class="ml-1" :disabled="!hasPrev()" @click="prev">
              {{ $t('catalog.xmlPreview.prev') }}
            </vs-button>
            <vs-button class="ml-1" :disabled="!hasNext()" @click="next">
              {{ $t('catalog.xmlPreview.next') }}
            </vs-button>
          </vs-col>
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>
<script>
import Vue from 'vue'
import moduleSearchCatalogMapping from '@/store/searchCatalogMapping/moduleSearchCatalogMapping'

export default Vue.extend({
  name: 'PreviewCatalogXml',
  data() {
    return {
      productNum: 0,
      maxPreviewItems: 5,
      showXmlPreview: false,
    }
  },
  computed: {
    getPreviewData() {
      return moduleSearchCatalogMapping.state.preview
    },
    getXmlProductPreviewData() {
      if (this.getPreviewData.length < this.productNum) {
        return []
      }
      return this.getPreviewData[this.productNum]
    },
    getMax() {
      return Math.min(this.maxPreviewItems - 1,
        this.getPreviewData.length)
    },
  },
  methods: {
    hasNext() {
      return this.productNum + 1 <= this.getMax
    },
    hasPrev() {
      return this.productNum > 0
    },
    next() {
      this.productNum = Math.min(
        this.productNum + 1,
        this.maxPreviewItems - 1,
        this.getPreviewData.length,
      )
    },
    prev() {
      this.productNum = Math.max(0, this.productNum - 1)
    },
  },
})
</script>

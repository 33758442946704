

































import RequiredFieldsStore, { RequiredField } from '@/store/searchCatalogMapping/requiredFields'
import Vue from 'vue'
import FeedPathSelect from './FeedPathSelect.vue'

export default Vue.extend({
  name: 'RequiredFieldTable',
  components: { FeedPathSelect },
  data() {
    return {
      columnNames: ['description', 'feedField', 'qonId'],
    }
  },
  computed: {
    requiredFields(): RequiredField[] {
      return RequiredFieldsStore.state.requiredFields
    },
  },
  methods: {
    updateRequiredFieldMapping(value: string, index: number) {
      RequiredFieldsStore.state.requiredFields[index].catalogField = value
    },
    updateRequiredFieldSeparatorMapping(value: string, index: number) {
      RequiredFieldsStore.state.requiredFields[index].separator = value
    },
  },
})

<template>
  <vs-row>
    <PreviewCatalogXml />
    <RequiredFieldTable />
    <AdditionalFields />
  </vs-row>
</template>

<script>
import PreviewCatalogXml from './mapping/PreviewCatalogXml.vue'
import RequiredFieldTable from './mapping/RequiredFieldTable.vue'
import AdditionalFields from './mapping/additionalFields/AdditionalFields.vue'

export default {
  name: 'MapData',
  components: {
    PreviewCatalogXml,
    AdditionalFields,
    RequiredFieldTable,
  },
}
</script>

<template>
  <b-row>
    <b-alert
      v-for="error in getErrors"
      :key="error"
      color="danger"
    >
      <span v-if="$t(error)">{{ $t(error) }}</span>
      <span v-else>{{ error }}</span>
    </b-alert>
  </b-row>
</template>
<script>
import { BAlert, BRow } from 'bootstrap-vue'
import Notifications from '@/store/notifications/notifications'

export default {
  name: 'WizardErrors',
  components: {
    BAlert,
    BRow,
  },
  computed: {
    getErrors() {
      return Notifications.state.errors
    },
  },
}
</script>

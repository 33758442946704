



































import Vue from 'vue'
import moduleSearchCatalogMapping from '@/store/searchCatalogMapping/moduleSearchCatalogMapping'

export default Vue.extend({
  name: 'FeedPathSelect',
  props: {
    fieldRow: {},
    rowIndex: {},
    onInputAction: {},
    onInputSeparatorAction: {},
  },
  computed: {
    availablePathsForSelect() {
      const availablePaths = [
        { key: 0, value: '', text: this.$t('catalogMapping.pickField') },
      ]
      const paths = moduleSearchCatalogMapping.state.foundPaths

      Object.keys(paths).forEach(key => {
        availablePaths.push(
          { key: Number(key), value: paths[key], text: paths[key] },
        )
      })
      return availablePaths
    },
  },
  methods: {
    isDanger(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? !validation.valid : false
    },
    isSuccess(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : false
    },
    getOneOfFoundPathRule(): string[] {
      console.log(moduleSearchCatalogMapping.state.foundPaths)
      return moduleSearchCatalogMapping.state.foundPaths
    },
  },
})

<template>
  <div>
    <h1>{{ $t('catalogImport.title') }}</h1>
    <b-row class="m-1">
      <span>{{ $t('catalogImport.selectedFeed') }}</span>
    </b-row>
    <b-row class="m-1">
      <strong>{{ getCatalogLink }}</strong>
    </b-row>

    <div v-if="errorOccurred">
      <vs-row>
        <vs-col class="text-danger">
          <vs-col>Wystąpił błąd!</vs-col>
          <vs-col> {{ errorOccurred }}</vs-col>
        </vs-col>
      </vs-row>
    </div>
    <div v-else-if="isCatalogAnalyzeInProgress">
      <b-row>
        <b-col class="text-center">
          {{ $t('catalogImport.analyzingCatalog') }}
          <b-spinner variant="primary" label="Spinning" />
        </b-col>
      </b-row>
      <b-row v-if="numberOfCorrectlyImportedProducts > -1">
        <b-col class="text-center">
          {{ $t('catalogImport.correctlyImported') }}: {{ numberOfCorrectlyImportedProducts }}
        </b-col>
      </b-row>
      <b-row v-if="numberOfIncorrectlyImportedProducts > -1">
        <b-col class="text-center">
          {{ $t('catalogImport.incorrectlyImported') }}: {{ numberOfIncorrectlyImportedProducts }}
        </b-col>
      </b-row>
    </div>
    <div v-else>
      <div v-if="shouldDisplayDownloadingProductsLabel">
        <b-row>
          <b-col class="text-center">
            {{ $t('catalogImport.downloadingProducts') }}
            <b-spinner variant="primary" label="Spinning" />
          </b-col>
        </b-row>
      </div>
      <vs-row v-if="haveImportedProducts">
        <h2>{{ $t('catalogImport.catalogProductsList.correctImportedProductListTitle') }}
          ({{ numberOfCorrectlyImportedProducts }})</h2>
        <vs-row>
          <CatalogImportProductList :products="getSuccessImportedProducts" />
        </vs-row>
      </vs-row>
      <vs-row v-if="haveImportedProducts && numberOfIncorrectlyImportedProducts > 0">
        <vs-row>
          <h2>{{ $t('catalogImport.catalogProductsList.incorrectlyImportedProductListTitle') }}
            ({{ numberOfIncorrectlyImportedProducts }}) </h2>
        </vs-row>
        <vs-row>
          <vs-collapse class="w-100 border-2" striped>
            <vs-collapse-item v-for="(pathFieldsWithWarnings, pathFieldName) in feedPathsWithWarnings" :key="pathFieldName" striped>
              <div slot="header" class="bold">
                Problemy ze zmapowaniem z <b>{{ pathFieldsWithWarnings[0].ElementPath }}</b> na <b>{{
                  pathFieldName
                }}</b> jest <b>{{ pathFieldsWithWarnings.length }}</b>
              </div>
              <vs-table :data="pathFieldsWithWarnings" stripe class="w-100" vs-w="12">
                <template slot="thead">
                  <vs-th>Typ danych</vs-th>
                  <vs-th>Warning</vs-th>
                  <vs-th>Element z katalogu (JSON)</vs-th>
                </template>
                <template v-slot="{data}">
                  <vs-tr v-for="(info, index) in data" :key="index" vs-w="12">
                    <vs-td>{{ info.DataType }}</vs-td>
                    <vs-td>{{ translateError(info.Error) }}</vs-td>
                    <vs-td>
                      <vs-textarea class="json-scroll" readonly :value="JSON.stringify(info.Element, null, &quot;\t&quot;)" />
                    </vs-td>
                  </vs-tr>
                </template>

              </vs-table>

            </vs-collapse-item>
          </vs-collapse>
        </vs-row>
        <vs-row>
          <vs-button
            color="red"
            class="mb-5"
            @click="downloadFile"
          >
            <file-text-icon />
            {{ $t('catalogImport.catalogProductsList.downloadFile') }}
          </vs-button>
        </vs-row>
      </vs-row>
    </div>
  </div>
</template>

<script>
import CatalogImportProductList from '@/views/components/catalog/CatalogImportProductList.vue'
import { FileTextIcon } from 'vue-feather-icons'
import apiRequest from '@/apiRequest'
import moduleImportedCatalog from '@/store/importedCatalog/moduleImportedCatalog'
import moduleCatalog from '@/store/catalog/moduleCatalog'

export default {
  name: 'CatalogImportSummary',
  components: {
    CatalogImportProductList,
    FileTextIcon,
  },
  computed: {
    getSuccessImportedProducts() {
      return moduleImportedCatalog.getters.getSuccessImportedProducts
    },
    haveImportedProducts() {
      return moduleImportedCatalog.getters.haveImportedProducts
    },
    getCatalogLink() {
      return moduleCatalog.getters.getCatalogLink
    },
    isCatalogAnalyzeInProgress() {
      return !moduleImportedCatalog.getters.isImportFinished
    },
    numberOfCorrectlyImportedProducts() {
      return moduleImportedCatalog.getters.correctlyImportedProductsCount
    },
    numberOfIncorrectlyImportedProducts() {
      return moduleImportedCatalog.getters.incorrectlyImportedProductsCount
    },
    shouldDisplayDownloadingProductsLabel() {
      return !this.isCatalogAnalyzeInProgress && !this.haveImportedProducts
    },
    errorOccurred() {
      return moduleImportedCatalog.getters.errorOccurred
    },
    feedPathsWithWarnings() {
      const getter = moduleImportedCatalog.getters.feedPathsWithWarnings
      console.log(getter)
      return getter
    },
  },
  methods: {
    downloadFile() {
      apiRequest.get('importCatalog/getIncorrectProducts', { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'errors.csv'
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    translateError(errorLabel) {
      return this.$t(`catalogImport.warnings.${errorLabel}`)
    },
  },
}
</script>

<style scoped>
.vs-pagination--ul {
  margin-bottom: 0;
}

.vs-pagination--li {
  width: auto;
  min-width: 35px;
}

.vs-pagination--li.is-current {
  padding-left: 3px;
  padding-right: 5px;
  min-width: 35px;
  border-radius: 35px;
}

.vs-pagination--li.is-current .effect {
  border-radius: 35px;
}

.con-vs-tooltip {
  display: inline-block
}
</style>

<style>
.json-scroll textarea {
  resize: none;
  overflow-y: scroll;
  height: 300px;
  max-width: 400px;
}
</style>

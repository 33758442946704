










import AdditionalFieldsStore from '@/store/searchCatalogMapping/additionalFields'
import Vue from 'vue'

export default Vue.extend({
  name: 'RemoveRowButton',
  props: {
    rowIndex: {
      required: true,
      type: Number,
    },
    canBeDeleted: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    removeFieldMapping(index: any) {
      AdditionalFieldsStore.commit('removeAdditionalField', index)
    },
  },
})

class FieldIdGenerator {
  public static generateFieldIdName(feedPath: string): string {
    return feedPath.replace('g:', '')
      .replace(/\s{2,}/g, ' ')
      .replace(' ', '_')
      .replace(/[.,/#!$%^&*;:{}=@`~()]/g, '_')
  }
}

export default FieldIdGenerator

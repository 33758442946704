









import AdditionalFieldsStore from '@/store/searchCatalogMapping/additionalFields'
import Vue from 'vue'

export default Vue.extend({
  name: 'AddFieldButton',
  methods: {
    addAdditionalField() {
      AdditionalFieldsStore.commit('addAdditionalField')
    },
  },
})

<template>
  <vs-row ref="container">
    <catalog-import-product-show-popup />
    <vs-row>
      <vs-table :data="products.correctProducts" stripe class="w-100" vs-w="12">
        <template slot="thead">
          <vs-th v-for="(name, columnIndex) in columnNames" :key="columnIndex">
            <span>{{ $t(name) }}</span>
          </vs-th>
        </template>
        <template v-for="(mainProduct) in products.correctProducts">
          <template v-for="(product, subIndex) in mainProduct.products">
            <CatalogImportProductRow
              v-if="subIndex === 0" :key="mainProduct.id + '_' + subIndex" :sub-products-count="mainProduct.products.length"
              :product="product" :opened="opened" :main-product-id="mainProduct.id"
              @click.native="toggle(mainProduct.id)"
            />
            <CatalogImportProductRow
              v-else :key="mainProduct.id+'_'+subIndex" :is-main="false"
              :product="product" :opened="opened" :main-product-id="mainProduct.id"
            />
          </template>
        </template>
      </vs-table>
    </vs-row>
    <vs-pagination v-model="page" :total="totalPages" :max="7" class="test" />

  </vs-row>
</template>

<script>
import CatalogImportProductRow from '@/views/components/catalog/CatalogImportProductRow.vue'
import CatalogImportProductShowPopup from '@/views/components/catalog/CatalogImportProductShowPopup.vue'
import moduleImportedCatalog from '@/store/importedCatalog/moduleImportedCatalog'

export default {
  name: 'CatalogImportProductList',
  components: {
    CatalogImportProductShowPopup,
    CatalogImportProductRow,
  },
  props: {
    products: {
      type: Object,
      default() {
        return { correctProducts: [] }
      },
    },
  },
  data() {
    return {
      columnNames: [
        'catalogImport.catalogProductsList.variantsCount',
        'catalogImport.catalogProductsList.title',
        'catalogImport.catalogProductsList.id',
        'catalogImport.catalogProductsList.price',
        'catalogImport.catalogProductsList.categories',
        'catalogImport.catalogProductsList.image',
        'catalogImport.catalogProductsList.actions',
      ],
      opened: '',
    }
  },
  computed: {
    isCatalogAnalyzeInProgress() {
      return !moduleImportedCatalog.getters.isImportFinished
    },
    totalPages() {
      return moduleImportedCatalog.state.importedProducts.totalPages
    },
    page: {
      get() {
        return moduleImportedCatalog.state.importedProducts.page
      },
      set(page) {
        moduleImportedCatalog.commit('setImportedProductsPage', page)
        moduleImportedCatalog.dispatch('getProductsList', this.$route.params.catalogId, { root: true })
      },
    },
  },
  watch: {
    page() {
      this.getProductsList()
    },
  },
  methods: {
    toggle(index) {
      if (this.opened === index) {
        this.opened = ''
        return
      }
      this.opened = index
    },
    getProductsList() {
      moduleImportedCatalog.dispatch('getProductsList', this.$route.params.catalogId)
    },
  },
}
</script>

<style scoped>

</style>

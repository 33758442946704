


















import Vue from 'vue'

export default Vue.extend({
  name: 'DataTypeSelect',
  props: {
    rowData: {
      required: true,
      type: Object,
    },
    rowIndex: {
      required: true,
      type: Number,
    },
    onInputAction: {
      required: true,
      type: Function,
    },
  },
  computed: {
    availableDataTypesForSelect() {
      return [
        { value: '', text: this.$t('catalogMapping.pickField') },
        { value: 'string', text: 'string' },
        { value: 'number', text: 'number' },
        { value: 'boolean', text: 'boolean' },
        { value: 'table', text: 'table' },
      ]
    },
  },
  methods: {
    isDanger(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? !validation.valid : false
    },
    isSuccess(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : false
    },
    getOneOfFoundPathRule() {
      return ['string', 'number', 'boolean', 'table']
    },
    validate() {
      (this.$refs.validateDataType as any).validate()
    },
  },
})

<template>
  <RemoveRowButton :row-index="rowIndex" :canBeDeleted="canBeDeleted()" />
</template>
<script>
import RemoveRowButton from './RemoveRowButton.vue'

export default {
  name: 'AdditionalRowActions',
  components: { RemoveRowButton },
  props: {
    rowIndex: {},
    field: {},
  },
  methods: {
    canBeDeleted() {
      return this.field.isDeletable
    },
  },
}
</script>

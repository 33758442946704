

































































import Vue from 'vue'
import AdditionalFieldsStore, { AdditionalField } from '@/store/searchCatalogMapping/additionalFields'
import FieldIdGenerator from '@/store/searchCatalogMapping/fieldIdGenerator'
import ProductDataHelper from '@/store/catalog/productDataHelper'
import FeedPathSelect from '../FeedPathSelect.vue'
import DataTypeSelect from '../DataTypeSelect.vue'
import AddFieldButton from './AddFieldButton.vue'
import AdditionalRowActions from './AdditionalRowActions.vue'

export default Vue.extend({
  name: 'AdditionalFields',
  components: {
    AdditionalRowActions, AddFieldButton, DataTypeSelect, FeedPathSelect,
  },
  props: {},
  data() {
    return {
      columnNames: ['feedField', 'qonId', 'dataType', 'action'],
    }
  },
  computed: {
    additionalFields(): AdditionalField[] {
      return AdditionalFieldsStore.state.additionalFields
    },
  },
  methods: {
    getAllQonAdditionalIds(refElementValue: any) {
      let refElementValueTest = refElementValue
      const usedQonIds = ProductDataHelper.getRequiredFieldsQonIds()
      AdditionalFieldsStore.state.additionalFields.forEach(additionalFields => {
        if (refElementValueTest === additionalFields.qonId) {
          refElementValueTest = null
          return
        }
        usedQonIds.push(additionalFields.qonId)
      })
      return usedQonIds
    },
    updateAdditionalFieldQonId(qonId: string, index: number) {
      AdditionalFieldsStore.state.additionalFields[index].qonId = qonId
    },
    updateAdditionalFieldCatalogField(path: string, index: number) {
      AdditionalFieldsStore.state.additionalFields[index].catalogField = path
      if (!AdditionalFieldsStore.state.additionalFields[index].qonId) {
        AdditionalFieldsStore.state.additionalFields[index].qonId = FieldIdGenerator.generateFieldIdName(path)
        AdditionalFieldsStore.state.additionalFields[index].dataType = 'string';
        (this.$refs.validateQonId as any)[index].validate();
        (this.$refs.validateDataTypeSelect as any)[index].validate()
      }
    },
    updateAdditionalFieldSeparatorMapping(separator: string, index: number) {
      AdditionalFieldsStore.state.additionalFields[index].separator = separator
    },
    updateAdditionalFieldDataType(dataType: string, index: number) {
      AdditionalFieldsStore.state.additionalFields[index].dataType = dataType
    },
    isDanger(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? !validation.valid : false
    },
    isSuccess(validation: { validated: boolean, valid: boolean | null }) {
      return validation.validated ? validation.valid : false
    },
    canBeDeletedField(additionalFieldsRow: any) {
      return additionalFieldsRow.isDeletable
    },
    canEditField(additionalFieldsRow: any) {
      return this.canBeDeletedField(additionalFieldsRow)
    },
  },
})

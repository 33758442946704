<template>
  <tbody :class="{hidden:isHidden}">
    <tr>
      <vs-td v-if="isMain">
        <a v-if="subProductsCount > 1">({{ subProductsCount }})
          <div><strong>{{ $t('catalogImport.catalogProductsList.show') }}</strong></div>
        </a>
      </vs-td>
      <vs-td v-if="!isMain">
        &nbsp;
      </vs-td>
      <vs-td>
        {{ product.title }}
      </vs-td>
      <vs-td>
        {{ mainProductId }}
      </vs-td>
      <vs-td>
        {{ product.price }}
      </vs-td>
      <vs-td>
        <span v-for="(element, key) in product.categories" :key="key">
          {{ element }},
        </span>
      </vs-td>
      <vs-td class="d-flex justify-content-center">
        <b-img
          fluid
          :src="generateQonLink(product.image_link)"
          :alt="product.title"
          class="productThumb"
          @error="setAlternativeImage($event)"
        />
      </vs-td>
      <vs-td>
        <a href="#" class="mr-1" @click.prevent="show(product, mainProductId)">
          <vs-tooltip text="Podgląd" class="d-flex justify-content-center">
            <eye-icon class="text-primary" />
          </vs-tooltip>
        </a>
      </vs-td>
    </tr>
  </tbody>
</template>
<script>
import { EyeIcon } from 'vue-feather-icons'
import store from '@/store'
import QonStaticImage from '@/libs/QonStaticImage'
import moduleImportedCatalog from '@/store/importedCatalog/moduleImportedCatalog'

export default {
  name: 'CatalogImportProductRow',
  components: {
    EyeIcon,
  },
  props: {
    product: {
      type: Object,
      default() {
        return {
          products: [],
          title: '',
          id: '',
          price: '',
          categories: '',
          image_link: '',
        }
      },
    },
    mainProductId: {
      type: String,
      default: '-2',
    },
    isMain: {
      type: Boolean,
      default: true,
    },
    opened: {
      type: String,
      default: '-1',
    },
    subProductsCount: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    isHidden() {
      return (!this.isMain && this.opened !== this.mainProductId)
    },
  },
  methods: {
    show(product, mainProductId) {
      moduleImportedCatalog.commit(
        'showProductPopup',
        { product, mainProductId },
      )
    },
    generateQonLink(url) {
      const { customerSymbol } = store.state.user.appActiveUser
      return QonStaticImage.generateQonLink(customerSymbol, url, 251, null)
    },
    setAlternativeImage(event) {
      QonStaticImage.setAlternativeImage(event)
    },
  },
}
</script>
<style scoped>
  .productThumb{
    max-height: 50px;
    max-width: 50px;
  }
  .vs-table--thead{
    z-index:auto;
  }
</style>
